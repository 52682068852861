import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Row, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BackendFilterProps,
  TransformedFilterProps,
} from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { SelectMobileFabricColumns } from '../../components/modal/fabric/SelectMobileFabricColumns';
import { defaultPageSize } from '../../components/table/TableGeneric';

import { TabTitle } from '../../components/TabTitle';
import { SelectFabricColumns } from '../../components/modal/fabric/SelectFabricColumns';
import { FabricTableGeneric } from '../../components/table/FabricTableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import {
  ButtonBasic,
  OrangeLogoButton,
} from '../../elements/buttons/StyledButtons';
import { NoAuthLayout } from '../../layouts/NoAuth';
import { encryptStorage } from '../../plugins/encryptStorage';
import i18n from '../../plugins/i18n';
import { setLanguage } from '../../plugins/redux/common';
import { getSharedCollection } from '../../utils/backendRequests/noAuth/getSharedCollection';
import { languageChoices } from '../../utils/multiLanguage/antdLocale';
import { sortKeysByTranslation } from '../../utils/parsers/sortByTranslation';
import { record, RootState, unitType } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function SharedCollectionPage({ title }: TitleProp) {
  const { id } = useParams(); // collection ID received from the router
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { language } = useSelector((state: RootState) => state.common);
  const [data, setData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [collectionName, setCollectionName] = useState<string>('');
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [selectedUnit, setSelectedUnit] = useState<string>('metric');
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  // Represents the range of filterable fields from the backend
  const [filterableFields, setFilterableFields] = useState<BackendFilterProps>(
    {}
  );
  // Represents the selected filter values to be sent to the backend
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);

  const sortedLanguageOptions = useMemo(
    () =>
      sortKeysByTranslation(
        Object.entries(languageChoices).map(([key]) => key)
      ),
    []
  );

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  useEffect(() => {
    setColumns(
      getSelectedColumns(
        'noAuth',
        selectedUnit,
        'designer',
        selectableColumnNames,
        'viewer'
      )
    );
  }, [selectableColumnNames]);

  const handleMetricMenuClick = (e: { key: string }) => {
    setSelectedUnit(e.key);
  };

  const handleLanguageMenuClick = (e: { key: string }) => {
    setSelectedLanguage(e.key);
    dispatch(setLanguage(e.key));
    i18n.changeLanguage(e.key);
    navigate(0);
  };

  const metricMenu: MenuProps['items'] = [
    {
      key: 'metric',
      label: i18n.t('units:metric'),
    },
    {
      key: 'imperial',
      label: i18n.t('units:imperial'),
    },
  ];

  // Dynamically generated language menu based on languageChoices
  const languageMenu: MenuProps['items'] = sortedLanguageOptions.map((key) => ({
    key,
    label: i18n.t(`language_options:${key}`), // Use translation keys dynamically
  }));

  async function getFabricsFromCollection() {
    const storedToken = encryptStorage.getItem('returnViewerToken');
    const res = await getSharedCollection(
      id as string,
      tableState,
      filterableColumns,
      storedToken,
      selectedUnit as unitType
    );
    if (res) {
      setData(res.fabrics);
      setCollectionName(res.name);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    } else {
      setData([]);
    }
  }

  async function getFabricsAndResetPage() {
    const storedToken = encryptStorage.getItem('returnViewerToken');
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getSharedCollection(
      id as string,
      tableState,
      filterableColumns,
      storedToken,
      selectedUnit as unitType
    );
    if (res) {
      setData(res.fabrics);
      setCollectionName(res.name);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    } else {
      setData([]);
    }
  }

  // Load data on page load
  useEffect(() => {
    getFabricsFromCollection();
  }, [id]);

  // Get Fabrics when user changes measurement units
  useEffect(() => {
    if (data.length > 0) getFabricsFromCollection();
  }, [selectedUnit]);

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) getFabricsFromCollection();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  // Get Filtered data when selected filters values change
  useEffect(() => {
    // Check if filterableFields is not an empty object
    // filterableFields represents the range of filterable fields from the backend
    if (Object.keys(filterableFields).length > 0) {
      getFabricsAndResetPage();
    }
  }, [filterableColumns]);

  const titleWithName =
    i18n.t('page_titles:shared_collection') + ': ' + collectionName;

  return (
    <NoAuthLayout>
      <HeaderTitle
        title={titleWithName}
        subtitle={i18n.t('page_subtitles:collection_no_auth_page')}
      />
      <TabTitle title={title} />
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'collection'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'collection'}
          selectableColumnNames={selectableColumnNames}
        />
      )}
      <Space direction="horizontal" size="small">
        <ButtonBasic
          onClick={() => setFilterOpen(true)}
          disabled={
            data.length === 0 && Object.keys(filterableColumns).length === 0
          }
        >
          {i18n.t('buttons:filter')}
        </ButtonBasic>
        <ButtonBasic
          onClick={() => setColumnModal({ visible: true })}
          disabled={data.length === 0}
        >
          {i18n.t('buttons:select_columns')}
        </ButtonBasic>
        <Dropdown
          menu={{ items: languageMenu, onClick: handleLanguageMenuClick }} // Proper menu prop usage
          trigger={['click']}
        >
          <ButtonBasic>
            {i18n.t(`language_options:${selectedLanguage}`)}
            <DownOutlined />
          </ButtonBasic>
        </Dropdown>
        <Dropdown
          menu={{ items: metricMenu, onClick: handleMetricMenuClick }} // Proper menu prop usage
          trigger={['click']}
        >
          <ButtonBasic>
            {selectedUnit === 'metric'
              ? i18n.t('units:metric')
              : i18n.t('units:imperial')}
            <DownOutlined />
          </ButtonBasic>
        </Dropdown>
        <a href="https://delvify.ai/" target="_blank" rel="noopener noreferrer">
          <OrangeLogoButton>{i18n.t('header:get_started')}</OrangeLogoButton>
        </a>
      </Space>
      <Row>
        <FabricFilterDrawer
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          setFilterableColumns={setFilterableColumns}
          filterableFields={filterableFields}
        />
        <FabricTableGeneric
          columns={columns}
          data={data}
          tableState={tableState}
          setTableState={setTableState}
          isBackendPagination={true}
          sortableColumns={sortableColumnNames}
        />
      </Row>
    </NoAuthLayout>
  );
}
