import {
  DownOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Col, Dropdown, Row, Space, Tag } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ButtonBasic,
  DropdownButton,
  SelectFabricsButton,
} from '../../../elements/buttons/StyledButtons';
import { ToggleButtonsContainer } from '../../../elements/fabrics/fabricStyledElement';
import { fabricPropertyArray } from '../../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../../utils/types/Types';
import { ExportSelectedRowsButton } from '../../fabric/ExportSelectedRowsButton';
import { FabricExportType } from '../../fabric/FabricTypes';
import { FabricFilterDrawer } from '../../filter/fabric/FabricFilterDrawer';
import { FabricSwitchToggle } from '../../gallery/FabricSwitchToggle';
import { GalleryView } from '../../gallery/GalleryView';
import { AddToCollectionModal } from '../../modal/collection/AddToCollectionModal';
import { SelectFabricColumns } from '../../modal/fabric/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../modal/fabric/SelectMobileFabricColumns';
import { CreateFabricsHangerRequest } from '../../modal/hangers/CreateFabricsHangerRequest';
import { AddToProjectModal } from '../../modal/project/AddToProjectModal';
import { CompareButton } from '../../table/CompareButton';
import { FabricTableGeneric } from '../../table/FabricTableGeneric';
import { columnFields } from '../../table/TableTypes';
import { getSelectedColumns } from '../../table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../table/fabricTable/ColumnsMobile';
import { intialDataProp, SearchResultsProps } from '../SearchTypes';

export type constructionDataProp = {
  construction: string;
}[];

export function FabricSearchResults({
  initialData,
  confidence,
  construction,
  searchType,
  tableState,
  setTableState,
  selectableColumnNames,
  sortableColumns,
  setFilterableColumns,
  filterableFields,
  filterableColumns,
  getFilteredFabrics,
}: SearchResultsProps) {
  // Data which gets fed to the table after it is filtered
  const [translatedData, setTranslatedData] = useState<intialDataProp>([]);
  const [translatedConstruction, setTranslatedConstruction] = useState<
    string | null
  >();
  // The currently selected row keys on the table
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const [exportType, setExportType] = useState<FabricExportType>(null);
  const [exportModal, setExportModal] = useState(false);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionModal, setCollectionModal] = useState({ visible: false });
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [includeSimilarity, setIncludeSimilarity] = useState<boolean>(false);
  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [requestHangerModal, setRequestHangerModal] = useState({
    visible: false,
  });
  const [isSelectAll, setIsSelectAll] = useState(false); // Track select/deselect state
  // Initialize fabricViewToggle: true if searchType is 'lace', otherwise, check localStorage
  // for a saved value ('true' or 'false'), defaulting to false if no value is found.
  // Current image search type is only lace, reminder to expand to other image search types
  const [fabricViewToggle, setFabricViewToggle] = useState<boolean>(
    searchType === 'lace' || localStorage.getItem('fabricViewToggle') === 'true'
  );

  // Ids of selected fabrics according to the filtered selection
  const selectedIds = useMemo(() => {
    if (initialData.length === 0) {
      return [];
    } else {
      return selectedKeys
        .filter((rowKey) => initialData[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => initialData[(rowKey as number) - 1]['id']);
    }
  }, [initialData, selectedKeys, fabricViewToggle]);

  useEffect(() => {
    const hasSimilarityScore = initialData.some(
      (record) => record.similarity_score !== undefined
    );
    setIncludeSimilarity(hasSimilarityScore);
  }, [initialData, searchType]);

  const toggleHangerRequestModal = () => {
    setRequestHangerModal({ visible: false });
  };

  useEffect(() => {
    // Current image search type is only lace, reminder to expand to other image search types
    if (searchType === 'lace') {
      setFabricViewToggle(true);
    }
  }, [searchType]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedKeys([]);
  }, [initialData]);

  //Handle Mobile Screen View
  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'search',
          measurementUnit,
          selectableColumnNames,
          role,
          account,
          includeSimilarity
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'search',
          measurementUnit,
          role,
          selectableColumnNames,
          account,
          includeSimilarity
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames, includeSimilarity]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      initialData,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [initialData]);

  // Get Filtered data when selected filters values change
  useEffect(() => {
    // Check if data has items and filterableFields is not an empty object
    // filterableFields represents the range of filterable fields from the backend
    if (initialData.length > 0 && Object.keys(filterableFields).length > 0) {
      //Call custom function here
      getFilteredFabrics();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedKeys([]);
  }, [initialData]);

  // Get construction translation data
  useEffect(() => {
    if (construction && construction.length > 0) {
      const contructionPropertyArray = [
        {
          name: 'construction',
          namespaceKey: 'construction_values',
          isTranslatable: true,
        },
      ];
      const constructionData: constructionDataProp = [
        { construction: construction as string },
      ];
      const translatedContruction = getTranslatedValuesAndUpdateData(
        constructionData,
        contructionPropertyArray
      );
      setTranslatedConstruction(
        translatedContruction[0].construction as string
      );
    } else {
      setTranslatedConstruction(construction);
    }
  }, [construction]);

  // Get construction translation data
  useEffect(() => {
    if (construction && construction.length > 0) {
      const contructionPropertyArray = [
        {
          name: 'construction',
          namespaceKey: 'construction_values',
          isTranslatable: true,
        },
      ];
      const constructionData: constructionDataProp = [
        { construction: construction as string },
      ];
      const translatedContruction = getTranslatedValuesAndUpdateData(
        constructionData,
        contructionPropertyArray
      );
      setTranslatedConstruction(
        translatedContruction[0].construction as string
      );
    } else {
      setTranslatedConstruction(construction);
    }
  }, [construction]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  // Makes sure something is selected before the compare button can be selected
  const hasSelected = selectedKeys.length > 0;

  const handleToggleChange = (checked: boolean) => {
    setFabricViewToggle(checked);
    // Current image search type is only lace, reminder to expand to other image search types
    // Only store in localStorage if searchType is not 'lace'
    if (searchType !== 'lace') {
      localStorage.setItem('fabricViewToggle', JSON.stringify(checked));
    }
  };

  useEffect(() => {
    // Update isSelectAll based on whether all items are selected
    const isAllSelected = selectedKeys.length === translatedData.length;
    setIsSelectAll(isAllSelected);
  }, [fabricViewToggle, selectedKeys, translatedData.length]);

  // Function to handle "Select All" / "Deselect All" toggle
  const handleSelectAllToggle = () => {
    if (isSelectAll) {
      // Deselect all
      setSelectedKeys([]);
    } else {
      // Select all
      // +1 to align with the index from the Ant Design table, which starts from 1
      setSelectedKeys(translatedData.map((_, index) => index + 1));
    }
    setIsSelectAll(!isSelectAll); // Toggle the button state
  };

  const handleCloseExport = () => {
    setExportModal(false);
  };

  // Handle export logic
  const handleExport = (type: string) => {
    if (type === 'pdf') {
      // Add PDF export logic
      setExportType('pdf');
    } else if (type === 'excel') {
      // Add Excel export logic
      setExportType('excel');
    }
    setExportModal(true);
  };

  // Define the menu for the dropdown
  const exportItems = [
    {
      key: 'export-pdf',
      label: (
        <DropdownButton onClick={() => handleExport('pdf')}>
          <Space>
            <FilePdfOutlined />
            {i18n.t('buttons:pdf')}
          </Space>
        </DropdownButton>
      ),
    },
    {
      key: 'export-excel',
      label: (
        <DropdownButton onClick={() => handleExport('excel')}>
          <Space>
            <FileExcelOutlined />
            {i18n.t('buttons:excel')}
          </Space>
        </DropdownButton>
      ),
    },
  ];

  const items = [
    {
      key: 'filter',
      label: (
        <DropdownButton
          disabled={initialData.length === 0}
          onClick={() => setFilterOpen(true)}
        >
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    ...(fabricViewToggle
      ? [
          {
            key: 'select-all',
            label: (
              <DropdownButton
                disabled={translatedData.length === 0}
                onClick={() => handleSelectAllToggle()}
              >
                {isSelectAll
                  ? i18n.t('headers:deselect_all')
                  : i18n.t('headers:select_all')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    {
      key: 'compare',
      label: (
        <CompareButton
          filteredData={initialData}
          selectedRowKeys={selectedKeys}
          isMobile={true}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton
          disabled={initialData.length === 0 || fabricViewToggle}
          onClick={() => setColumnModal({ visible: true })}
        >
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    ...(account !== 'viewer' && role === 'supplier'
      ? [
          {
            key: 'add-to-collection',
            label: (
              <DropdownButton
                disabled={!hasSelected}
                onClick={() => setCollectionModal({ visible: true })}
              >
                {i18n.t('buttons:add_to_collection')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    ...(role === 'designer'
      ? [
          {
            key: 'add-to-project',
            label: (
              <DropdownButton
                onClick={() => setProjectModal({ visible: true })}
                disabled={!hasSelected}
              >
                {i18n.t('buttons:add_to_project')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    // Conditionally include the request hanger button for designers only
    ...(role === 'designer'
      ? [
          {
            key: 'hanger',
            label: (
              <DropdownButton
                onClick={() => setRequestHangerModal({ visible: true })}
                disabled={initialData.length === 0 || selectedKeys.length === 0}
              >
                {i18n.t('buttons:request_hanger')}
              </DropdownButton>
            ),
          },
        ]
      : []),
  ];
  return (
    <div style={{ width: '100%' }}>
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'search'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'search'}
          selectableColumnNames={selectableColumnNames}
          includeSimilarity={includeSimilarity}
        />
      )}
      {role === 'designer' && (
        <AddToProjectModal
          modal={projectModal}
          setModal={setProjectModal}
          fabricIds={selectedIds}
        />
      )}
      <AddToCollectionModal
        modal={collectionModal}
        setModal={setCollectionModal}
        fabricIds={selectedIds}
      />
      <CreateFabricsHangerRequest
        visible={requestHangerModal.visible}
        onCancel={toggleHangerRequestModal}
        fabricIds={selectedIds}
      />
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 8 : undefined}>
          {isMobileFlag ? (
            <Space>
              <Dropdown menu={{ items }}>
                <ButtonBasic>
                  <Space>
                    {i18n.t('buttons:Options')}
                    <DownOutlined />
                  </Space>
                </ButtonBasic>
              </Dropdown>
              <Dropdown
                menu={{ items: exportItems }}
                trigger={['click']}
                disabled={!hasSelected}
              >
                <ButtonBasic>
                  <Space>
                    {i18n.t('buttons:export')}
                    <DownOutlined />
                  </Space>
                </ButtonBasic>
              </Dropdown>
            </Space>
          ) : (
            <Space direction="horizontal" size="small">
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  initialData.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              <CompareButton
                filteredData={translatedData}
                selectedRowKeys={selectedKeys}
              />
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={initialData.length === 0 || fabricViewToggle}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              {role === 'designer' && (
                <ButtonBasic
                  onClick={() => setRequestHangerModal({ visible: true })}
                  disabled={
                    initialData.length === 0 || selectedKeys.length === 0
                  }
                >
                  {i18n.t('buttons:request_hanger')}
                </ButtonBasic>
              )}
              <Dropdown
                menu={{ items: exportItems }}
                trigger={['click']}
                disabled={!hasSelected}
              >
                <ButtonBasic>
                  <Space>
                    {i18n.t('buttons:export')}
                    <DownOutlined />
                  </Space>
                </ButtonBasic>
              </Dropdown>
              {role === 'designer' && (
                <>
                  <ButtonBasic
                    block
                    disabled={!hasSelected}
                    onClick={() => setProjectModal({ visible: true })}
                  >
                    {i18n.t('headers:add_to_project')}
                  </ButtonBasic>
                </>
              )}
              {account !== 'viewer' && role === 'supplier' && (
                <>
                  <ButtonBasic
                    block
                    disabled={!hasSelected}
                    onClick={() => setCollectionModal({ visible: true })}
                  >
                    {i18n.t('headers:add_to_collection')}
                  </ButtonBasic>
                </>
              )}
            </Space>
          )}
        </Col>
        <ToggleButtonsContainer>
          <Row align="middle" gutter={8}>
            {fabricViewToggle && !isMobileFlag && (
              <Col>
                <SelectFabricsButton
                  onClick={() => handleSelectAllToggle()}
                  disabled={translatedData.length === 0}
                >
                  {isSelectAll
                    ? i18n.t('headers:deselect_all')
                    : i18n.t('headers:select_all')}
                </SelectFabricsButton>
              </Col>
            )}
            <Col>
              <FabricSwitchToggle
                fabricViewToggle={fabricViewToggle}
                handleToggleChange={handleToggleChange}
              />
            </Col>
          </Row>
        </ToggleButtonsContainer>
        {/* Render confidence and construction info */}
        {isMobileFlag && confidence && construction && (
          <Col span={24} style={{ marginTop: '20px' }}>
            <Tag>
              {i18n.t('fabric_fields:construction')}: {translatedConstruction} (
              {i18n.t('headers:probability')} {confidence}%)
            </Tag>
          </Col>
        )}
      </Row>
      {/* Render confidence and construction info below buttons for desktop */}
      {!isMobileFlag && confidence && construction && (
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Tag>
              {i18n.t('fabric_fields:construction')}: {translatedConstruction} (
              {i18n.t('headers:probability')} {confidence}%)
            </Tag>
          </Col>
        </Row>
      )}
      <ExportSelectedRowsButton
        visible={exportModal}
        onCancel={handleCloseExport}
        data={translatedData}
        selectedRowKeys={selectedKeys}
        exportName={i18n.t('headers:fabric_search_result')}
        exportType={exportType}
      />
      <Row>
        <Col span={24}>
          <FabricFilterDrawer
            filterOpen={filterOpen}
            closeFilter={() => setFilterOpen(false)}
            setFilterableColumns={setFilterableColumns}
            filterableFields={filterableFields}
          />
          {fabricViewToggle ? (
            <GalleryView
              fabrics={translatedData}
              tableState={tableState}
              setTableState={setTableState}
              isMobile={isMobileFlag}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              pageType={'search'}
            />
          ) : (
            <FabricTableGeneric
              rowSelection={rowSelection}
              columns={columns}
              data={translatedData}
              tableState={tableState}
              setTableState={setTableState}
              isBackendPagination={true}
              sortableColumns={sortableColumns}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
