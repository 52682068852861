import i18n from 'i18next';

import { useEffect, useState } from 'react';
import {
  LibraryListType,
  libraryProp,
} from '../../../utils/backendRequests/list/libraries';
import { Setter } from '../../../utils/types/Types';
import { SelectWithSearch } from '../../search/SelectWithSearch';

interface LibraryDropdownProps {
  value: string | undefined; // Accept undefined as well
  setLibraryId: Setter<string>;
  libraries: LibraryListType;
  disabled?: boolean; // Add the optional disabled property
}

export const LibraryDropdown = ({
  value,
  setLibraryId,
  libraries,
  disabled = false, // Default to false
}: LibraryDropdownProps) => {
  const [libraryOptions, setLibraryOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  // Prepare options for SelectWithSearch
  useEffect(() => {
    if (libraries && libraries.length > 0) {
      const uploadLibraryOptions = libraries.map((library: libraryProp) => ({
        value: library.id,
        label: library.name,
      }));
      setLibraryOptions(uploadLibraryOptions);
    }
  }, [libraries, value]);

  const handleSelect = (value: string) => {
    setLibraryId(value);
  };

  return (
    <SelectWithSearch
      style={{ width: '100%' }}
      placeholder={i18n.t('long_messages:message_library_dropdown')}
      value={value || undefined} // Ensure undefined when no value
      onChange={handleSelect}
      allowClear // Allow clearing the selection
      options={libraryOptions} // Pass the options to SelectWithSearch
      disabled={disabled}
    />
  );
};
