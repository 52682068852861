import { Button, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { UpdateBrandImage } from '../../../elements/modals/StyledModal';
import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import i18n from '../../../plugins/i18n';
import { Brand } from '../../../utils/backendRequests/admin/AdminTypes';
import { editBrandLogo } from '../../../utils/backendRequests/admin/editBrandLogo';
import { editDesignerBrand } from '../../../utils/backendRequests/admin/editDesignerBrand';
import { parseBrandsResponse } from '../../../utils/backendRequests/admin/getBrands';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { DropdownOption, SetPropsWithDropDownObj } from '../../form/FormTypes';
import { DesignerBrandValues } from '../../table/TableTypes';
import { ImageUpload } from '../../upload/ImageUpload';
import { commonBrandFields } from './brandFormFields';
import { brandFieldRules } from './brandFormRules';

interface UpdateDesignerBrandProps {
  modal: { visible: boolean };
  setModal: (modal: { visible: boolean }) => void;
  brand: Brand | parseBrandsResponse;
  designerBrandId: string;
  getBrands: () => void;
}

type dropDownDataType = {
  country_id: {
    value: number;
    label: string;
  }[];
};

export function UpdateDesignerBrandModal({
  modal,
  setModal,
  brand,
  designerBrandId,
  getBrands,
}: UpdateDesignerBrandProps) {
  const [form] = Form.useForm();
  const [logoImg, setLogoImg] = useState<File | null>(null);
  const [logoImgUrl, setLogoImgUrl] = useState<string | null>(null);
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  // Generic function to translate field titles
  const parseFieldTitle = (fieldName: string) => {
    return i18n.t(`${fieldName}`);
  };

  const fabricRulesConst = useMemo(
    () => brandFieldRules(commonBrandFields, parseFieldTitle),
    []
  );
  const uploadLogo = (
    <ImageUpload
      fieldName={i18n.t('headers:logo')}
      setImage={setLogoImg}
      imageUrl={logoImgUrl}
      setImageUrl={setLogoImgUrl}
    />
  );

  useEffect(() => {
    if (
      modal.visible &&
      brand &&
      Object.keys(propsWithDropDownObj).length > 0
    ) {
      //TODO: This logic needs to be removed in future where
      //return ID not string from backend
      const findMatchingOption = (options: DropdownOption[], label: string) =>
        options?.find((option) => option.label === label)?.value ?? null;

      // Prepare updated brand object without mutating the original
      const updatedBrand = {
        ...brand,
        country_id: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.country_id,
          brand.country ?? ''
        ),
      };

      // Set form values and logo URL for the new brand
      form.setFieldsValue(updatedBrand);
      setLogoImgUrl(brand?.logo_image_url);
    }
  }, [modal.visible, propsWithDropDownObj]);

  // Close modal
  const handleCancel = () => {
    setModal({ visible: false });
  };

  const handleSubmit = async (values: DesignerBrandValues) => {
    const parsedBrand = nullParser(values) as DesignerBrandValues;
    let isBrandEdited = false;
    let isLogoEdited = false;

    if (await editDesignerBrand(parsedBrand, designerBrandId)) {
      isBrandEdited = true;
      setModal({ visible: false });
    }
    if (logoImg) {
      const formData: FormData = new FormData();
      formData.append('logo_image', logoImg);
      if (await editBrandLogo(formData, designerBrandId, 'designer')) {
        setLogoImg(null);
        setLogoImgUrl(null);
        isLogoEdited = true;
      }
    }
    if (isBrandEdited || isLogoEdited) {
      getBrands();
    }
  };

  return (
    <Modal
      title={`Update Designer Brand`}
      open={modal.visible}
      onCancel={handleCancel}
      form={form}
      footer={null}
    >
      <UpdateBrandImage direction="vertical" size="large">
        {uploadLogo}
      </UpdateBrandImage>
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={commonBrandFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={
              setPropsWithDropDownObj as SetPropsWithDropDownObj
            }
            parseFieldTitle={parseFieldTitle}
            formRules={fabricRulesConst}
          />
          <Form.Item wrapperCol={{ offset: 20, span: 12 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:update')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
