import { useState } from 'react';

import { LinkStyledButton } from '../../../elements/buttons/StyledButtons';
import { Brand } from '../../../utils/backendRequests/admin/AdminTypes';
import { parseBrandsResponse } from '../../../utils/backendRequests/admin/getBrands';
import { UpdateDesignerBrandModal } from '../../modal/createBrand/UpdateDesignerBrandModal';

interface EditDesignerBrandProps {
  brand: Brand | parseBrandsResponse;
  designerBrandId: string;
  getBrands: () => void;
}

export function EditDesignerBrand({
  brand,
  designerBrandId,
  getBrands,
}: EditDesignerBrandProps) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <LinkStyledButton type="link" onClick={handleUpdate}>
          Edit
        </LinkStyledButton>
      </div>
      <UpdateDesignerBrandModal
        modal={updateModal}
        setModal={setUpdateModal}
        brand={brand}
        designerBrandId={designerBrandId}
        getBrands={getBrands}
      />
    </>
  );
}
