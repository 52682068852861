import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Creates a new fabric entry.
 */
export async function createFabric(data: FormData): Promise<boolean> {
  try {
    await api({
      method: 'POST',
      url: 'v1/fabric',
      body: data,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    message.success(i18n.t('long_messages:message_fabric_created_success'));
    return true;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === 'There are duplicating fabric(s)') {
        message.error(i18n.t('error_messages:error_duplicate_fabrics_failed'));
      } else {
        message.error(i18n.t('error_messages:error_fabric_created_failed'));
      }
    } else {
      message.error(i18n.t('error_messages:error_fabric_created_failed'));
    }
    return false;
  }
}
