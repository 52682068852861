import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { CreateBulkUploadRow } from '../../../elements/modals/StyledModal';
import { editBulkUpload } from '../../../utils/backendRequests/bulkUpload/editBulkUpload';
import { Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';

interface ModalProps {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  fetchData: () => void;
}

export function EditBulkUploadModal({
  modal,
  setModal,
  fetchData,
}: ModalProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (!modal.visible) {
      setFileList([]);
    }
  }, [modal.visible]);

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleFileRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const handleOk = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const file = fileList[0];
      formData.append('xlsx_file', file.originFileObj as Blob);
      const response = await editBulkUpload(formData);

      if (response) {
        setModal({ visible: false });
        setFileList([]);
        fetchData();
      } else {
        // Handle the error case
        message.error(i18n.t('error_messages:error_upload_failed'));
      }
    } else {
      // Handle the case where no file is selected or library is not set
      message.error(i18n.t('error_messages:error_select_file_library'));
    }
  };

  const props = {
    maxCount: 1,
    accept: '.xlsx',
    beforeUpload: () => false, // Allow files of any size
    onChange: handleFileChange,
    onRemove: handleFileRemove,
    fileList,
  };

  return (
    <Modal
      title={i18n.t('headers:edit_fabrics')}
      open={modal.visible}
      onOk={handleOk}
      cancelButtonProps={{ danger: true }}
      onCancel={() => setModal({ visible: false })}
    >
      <Row style={{ marginBottom: -8 }}>
        <Col span={24}>
          <p>1. {i18n.t('long_messages:message_bulk_step_1')}</p>
        </Col>
      </Row>
      <Row style={{ marginBottom: -8 }}>
        <Col span={24}>
          <p>2. {i18n.t('long_messages:message_bulk_step_2')}</p>
        </Col>
      </Row>
      <Row style={{ marginBottom: -8 }}>
        <Col span={24}>
          <p>3. {i18n.t('long_messages:message_bulk_step_3')}</p>
        </Col>
      </Row>
      <CreateBulkUploadRow>{i18n.t('headers:input_file')}:</CreateBulkUploadRow>
      <Row wrap={false}>
        <Upload {...props}>
          <Button disabled={fileList.length !== 0} icon={<UploadOutlined />}>
            {i18n.t('buttons:select_files')}
          </Button>
        </Upload>
      </Row>
    </Modal>
  );
}
