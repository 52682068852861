import { Button, Checkbox, Col, Input, Pagination, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import {
  CollectionFieldsContainer,
  SelectableFieldContainer,
} from '../../../elements/modals/StyledModal';
import { copyCollectionToCollection } from '../../../utils/backendRequests/collection/copyCollectionToCollection';
import { copyLibraryFabricsToCollection } from '../../../utils/backendRequests/collection/copyLibraryFabricsToCollection';
import { createCollection } from '../../../utils/backendRequests/collection/createCollection';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { RootAuth, Setter } from '../../../utils/types/Types';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { formFieldsProp } from '../../form/FormTypes';

type CreateCollectionModalProps = {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  setParentModal?: Setter<{ visible: boolean }>;
  fabricIds?: string[];
  libraryId?: string | null;
  getData: () => void;
  collectionId?: string | null;
};

export const defaultFieldsToShowOptions = [
  'name',
  'fabric_type',
  'image_url_front',
  'supplier_brand_name',
  'updated_at',
  'created_at',
  'ml_pattern_tags',
  'ml_description',
  'construction',
];

export const fieldToShowOptions = [
  'country_of_origin',
  'original_supplier',
  'weight_grams_per_sqm',
  'weight_grams_per_m',
  'weight_grams_per_piece',
  'length_cm_per_piece',
  'width_cm',
  'compositions',
  'image_url_back',
  'image_url_macro',
  'image_url_header',
  'description',
  'notes',
  'colours',
  'colour_fastness',
  'wash_care',
  'piling',
  'gauge_inch',
  'yarn_count',
  'stretch',
  'functional_finish',
  'season',
  'creation_date',
  'usage_category',
  'look',
  'special_yarn',
  'sustainability',
  'hand_feel',
  'repeat',
  'lace_structure',
  'pattern_design',
  'aesthetic_finish',
  'edge_finish',
  'price_per_sqm',
  'price_per_m',
  'price_per_kg',
  'price_per_piece',
  'lead_time_days',
  'moq_m',
  'mcq_m',
  'moq_sqm',
  'mcq_sqm',
  'moq_piece',
  'mcq_piece',
  'moq_kg',
  'mcq_kg',
  'availability',
  'stock_m',
  'stock_sqm',
  'stock_piece',
  'stock_kg',
  'attachments',
  'certifications',
  'name_from_original_supplier',
  'coverage',
  'cuttable_width_cm',
  'lace_type',
];

const MAX_GROUPS_PER_PAGE = 2; // Maximum of 2 groups per page (except for 'basic')

export function CreateCollectionModal({
  modal,
  setModal,
  setParentModal = () => {},
  fabricIds = [],
  libraryId = null,
  getData,
  collectionId = null,
}: CreateCollectionModalProps) {
  const [collectionName, setCollectionName] = useState<string>('');
  const [description, setDescription] = useState<string | null>(null);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectAllMode, setSelectAllMode] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1); // For pagination
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  useEffect(() => {
    if (!modal.visible) {
      setCollectionName('');
      setDescription(null);
      setSelectedFields([]);
      setCurrentPage(1); // Reset pagination when modal is closed
    }
  }, [modal.visible]);

  useEffect(() => {
    setSelectAllMode(
      selectedFields.length < combinedFieldOptions.length &&
        selectedFields.length > defaultFieldsToShowOptions.length
    );
  }, [selectedFields]);

  const handleFieldChange = (field: string, checked: boolean) => {
    if (checked) {
      setSelectedFields((prev) => [...prev, field]);
    } else {
      setSelectedFields((prev) => prev.filter((item) => item !== field));
    }
  };

  const handleSuccess = () => {
    setCollectionName('');
    setDescription(null);
    setModal({ visible: false });
    setParentModal({ visible: false });
    getData();
  };

  const handleCancel = () => {
    setModal({ visible: false });
    setParentModal({ visible: true });
  };

  const handleSelectAll = () => {
    setSelectedFields((prev) => [
      ...new Set([
        ...prev,
        ...combinedFieldOptions.filter(
          (field) => !defaultFieldsToShowOptions.includes(field)
        ),
      ]),
    ]);
  };

  const handleDeselectAll = () => {
    setSelectedFields((prev) =>
      prev.filter((field) => defaultFieldsToShowOptions.includes(field))
    );
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  const handleSubmit = async () => {
    /**
     * Either:
     * - Creates a new collection with all the fabrics given to it,
     * - Creates a new empty collection and then adds all the fabrics in a fabric library to it.
     */
    // Only send non default fields to show to backend
    const selectedCustomFields = selectedFields.filter(
      (field) => !defaultFieldsToShowOptions.includes(field)
    );

    const isNameValid = stringValid(
      collectionName,
      50,
      i18n.t('common:name'),
      false
    );

    const isDescriptionValid = stringValid(
      description,
      500,
      i18n.t('column_titles:description'),
      true
    );
    if (isNameValid && isDescriptionValid) {
      const descriptionValue = description === '' ? null : description;
      if (libraryId === null) {
        await createCollection(
          collectionName,
          descriptionValue,
          fabricIds,
          selectedCustomFields
        ).then((collection) => {
          if (collectionId) {
            copyCollectionToCollection(collectionId, collection.id);
          }
          handleSuccess();
        });
      } else {
        await createCollection(
          collectionName,
          descriptionValue,
          fabricIds,
          selectedCustomFields
        ).then((collection) => {
          copyLibraryFabricsToCollection(collection.id, libraryId).then(() => {
            handleSuccess();
          });
        });
      }
    }
  };

  // Paginate the combinedFieldOptions
  const combinedFieldOptions = [
    ...defaultFieldsToShowOptions,
    ...fieldToShowOptions,
  ];

  const combinedColumns = returnOrderedFields(combinedFieldOptions);

  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  const groupedColumns = groupColumnsByType(combinedColumns);

  const getPaginatedColumns = (
    groupedColumns: Record<string, formFieldsProp[]>,
    currentPage: number
  ) => {
    const groups = Object.entries(groupedColumns);

    // Separate basic group from others
    const basicGroup = groups.find(([group]) => group === 'basic');
    const otherGroups = groups.filter(([group]) => group !== 'basic');

    // Adjust pagination based on group types
    const isBasicPage = currentPage === 1 && basicGroup;

    // Calculate start and end indices for pagination
    const startIndex = isBasicPage
      ? 0
      : (currentPage - 2) * MAX_GROUPS_PER_PAGE; // Subtract 2 for other groups
    const endIndex = startIndex + MAX_GROUPS_PER_PAGE;

    // Get the paginated groups from the other groups
    const paginatedOtherGroups = otherGroups
      .slice(startIndex, endIndex)
      .map(([group, columns]) => ({
        group,
        columns,
      }));

    // Combine groups: Add basic group (if it's a basic page) and an empty grouping
    const combinedGroups = isBasicPage
      ? [
          { group: basicGroup[0], columns: basicGroup[1] }, // Add the basic group
        ]
      : paginatedOtherGroups; // If not basic page, just return other groups

    // Calculate total pages
    const totalPages = Math.ceil(
      // Count the groups for pagination
      (basicGroup ? 1 : 0) + Math.ceil(otherGroups.length / MAX_GROUPS_PER_PAGE)
    );

    return { combinedGroups, totalPages }; // Return both combined groups and total pages
  };

  const { combinedGroups, totalPages } = getPaginatedColumns(
    groupedColumns,
    currentPage
  );

  const handleGroupSelectAll = (group: string) => {
    const columns = groupedColumns[group];
    const allFieldNames = columns.map((col) => col.name);
    const allFieldsSelected = allFieldNames.every((name) =>
      selectedFields.includes(name)
    );

    if (allFieldsSelected) {
      // Deselect all fields in the group
      setSelectedFields((prev) =>
        prev.filter((name) => !allFieldNames.includes(name))
      );
    } else {
      // Select all fields in the group
      setSelectedFields((prev) => [
        ...prev,
        ...allFieldNames.filter((name) => !prev.includes(name)),
      ]);
    }
  };

  const renderGroups = combinedGroups.map(({ group, columns }) => {
    // Split the columns into three separate arrays for each column
    const columnCount = 3;
    const columnsPerGroup = Math.ceil(columns.length / columnCount);
    const columnsInGroups = Array.from({ length: columnCount }, (_, i) =>
      columns.slice(i * columnsPerGroup, (i + 1) * columnsPerGroup)
    );

    return (
      <Col span={24} key={group}>
        <Space>
          <h4 style={{ margin: '0 0 0px 0' }}>{i18n.t(`headers:${group}`)}</h4>
          <Checkbox
            checked={columns.every((col) => selectedFields.includes(col.name))}
            onChange={() => handleGroupSelectAll(group)}
          ></Checkbox>
        </Space>
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          {/* Render each group of columns */}
          {columnsInGroups.map((colGroup, colIndex) => (
            <Col
              key={`group-col-${group}-${colIndex}`}
              span={Math.floor(24 / columnCount)} // Each column takes up 1/3 of the width
            >
              {colGroup.map((col, index) => (
                <SelectableFieldContainer key={`${group}-${col.name}-${index}`}>
                  <Checkbox
                    checked={
                      selectedFields.includes(col.name) ||
                      defaultFieldsToShowOptions.includes(col.name)
                    }
                    disabled={defaultFieldsToShowOptions.includes(col.name)}
                    onChange={(e) =>
                      handleFieldChange(col.name, e.target.checked)
                    }
                  />
                  <span style={{ marginLeft: '8px' }}>
                    {parseFabricTitles(col.name, measurementUnit)}
                  </span>
                </SelectableFieldContainer>
              ))}
            </Col>
          ))}
        </Row>
      </Col>
    );
  });

  return (
    <Modal
      title={i18n.t('headers:new_collection')}
      open={modal.visible}
      width={700}
      onCancel={handleCancel}
      onOk={handleSubmit}
      footer={
        <>
          <Button onClick={handleToggleAll}>
            {selectAllMode
              ? i18n.t('buttons:deselect_all')
              : i18n.t('buttons:select_all')}
          </Button>
          <Button danger onClick={handleCancel}>
            {i18n.t('buttons:cancel')}
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            {i18n.t('buttons:ok')}
          </Button>
        </>
      }
    >
      {`${i18n.t('common:name')}:`}
      <Input
        value={collectionName}
        onChange={(e) => setCollectionName(e.target.value)}
      />
      {`${i18n.t('column_titles:description')}:`}
      <Input
        showCount
        value={description !== null ? description : ''}
        style={{ marginTop: '10px', marginBottom: '10px' }}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Space style={{ paddingBottom: '10px' }}>
        {i18n.t('column_titles:fabric_fields_to_show')}
        <HelpIcon
          title={i18n.t('long_messages:message_fabric_show_coumns_message')}
          margin="0 0px 0 -3px"
        />
      </Space>
      <Row gutter={[8, 8]}>{renderGroups}</Row>
      <CollectionFieldsContainer>
        <Pagination
          current={currentPage}
          total={totalPages}
          pageSize={1}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: '20px', textAlign: 'center' }}
          showSizeChanger={false}
        />
      </CollectionFieldsContainer>
    </Modal>
  );
}
