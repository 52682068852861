import { Button } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';

import { Color } from '../../../elements/base/Color';
import { binCollection } from '../../../utils/backendRequests/collection/binCollection';
import { Modal } from '../../Modal';
import { BinCollectionButtonProps } from './CollectionListTypes';

export function BinCollectionButton({
  collectionId,
  getCollectionsList,
}: BinCollectionButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleConfirm = async () => {
    // Bin Collection
    const response = await binCollection(collectionId);
    if (response) {
      getCollectionsList();
      setModalVisible(false); // Close the modal after deletion
    }
  };

  return (
    <>
      <Button danger onClick={() => setModalVisible(true)}>
        {i18n.t('buttons:delete')}
      </Button>
      <Modal
        title={i18n.t('headers:confirm_deletion')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_collection_deletion')}</p>
      </Modal>
    </>
  );
}
