import i18n from 'i18next';

import { message } from 'antd';
import { record } from '../../types/Types';
import { api } from '../api/api';
/**
 * Edits a fabric entry.
 * Only text parts, not images.
 */
export async function editFabric(
  fabricId: string,
  parsedData: record // TODO: Create type for submitted data
): Promise<boolean> {
  try {
    await api({
      method: 'PUT',
      url: `v1/fabric/${fabricId}`,
      body: parsedData,
    });
    return true;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === 'There are duplicating fabric(s)') {
        message.error(i18n.t('error_messages:error_duplicate_fabrics_failed'));
      } else {
        message.error(i18n.t('error_messages:error_fabric_updated_failed'));
      }
    } else {
      message.error(i18n.t('error_messages:error_fabric_updated_failed'));
    }
    return false;
  }
}
