import { Button, Col, Row, Upload, message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Color } from '../../elements/base/Color';
import {
  FileActionCol,
  FileNameCol,
  FileText,
} from '../../elements/modals/StyledModal';
import { StyledAttachmentContainer } from '../../elements/StyledUI';
import { FileWrapper } from '../../elements/wrapper/FileWrapper';
import i18n from '../../plugins/i18n';
import { RootState, Setter } from '../../utils/types/Types';
import { Modal } from '../Modal';
import { AddFileTitle } from './AddFileTitle';

interface FileUploadProps {
  setAttachments: Setter<File[] | null>;
}

export interface CustomUploadRequestOption {
  file: File;
  [key: string]: unknown;
}

export function FileUpload({ setAttachments }: FileUploadProps) {
  const [fileList, setFileList] = useState<File[]>([]);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  const showRemoveModal = (file: File) => {
    setSelectedFile(file);
    setRemoveModalVisible(true);
  };

  const handleRemoveFile = () => {
    if (selectedFile) {
      setFileList((prevFileList) =>
        prevFileList.filter((file) => file !== selectedFile)
      );
      setAttachments((prevAttachments) =>
        prevAttachments
          ? prevAttachments.filter((file) => file !== selectedFile)
          : prevAttachments
      );
      setRemoveModalVisible(false);
    }
  };

  const handleCancelRemove = () => {
    setSelectedFile(null);
    setRemoveModalVisible(false);
  };

  const props = {
    multiple: true,
    showUploadList: false,
    beforeUpload: (_: File, bulkFileList: File[]) => {
      if (fileList.length + bulkFileList.length > 3) {
        message.error(i18n.t('error_messages:error_upload_too_many_files'));
        return false;
      }
    },
    customRequest(options: unknown) {
      const { file } = options as CustomUploadRequestOption;
      setFileList((prevFileList) => [...prevFileList, file]);
      setAttachments((prevAttachments) =>
        prevAttachments
          ? prevAttachments.length < 3
            ? [...prevAttachments, file]
            : prevAttachments
          : [file]
      );
    },
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <Modal
        title={i18n.t('headers:delete_attachment')}
        open={removeModalVisible}
        onOk={handleRemoveFile}
        onCancel={handleCancelRemove}
        okText={i18n.t('buttons:remove')}
        cancelText={i18n.t('buttons:cancel')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        {i18n.t('long_messages:confirm_attachment_delete')}
      </Modal>
      <StyledAttachmentContainer
        direction="vertical"
        $isMobileFlag={isMobileFlag}
      >
        <Upload {...props}>
          <FileWrapper>
            <AddFileTitle />
          </FileWrapper>
        </Upload>
        <Row gutter={[16, 16]}>
          {fileList.map((file, index) => (
            <Col key={index} span={24}>
              <Row justify="space-between">
                <FileNameCol span={16} $isMobileFlag={isMobileFlag}>
                  <FileText>{file.name}</FileText>
                </FileNameCol>
                <FileActionCol span={8} $isMobileFlag={isMobileFlag}>
                  <Button type="link" onClick={() => showRemoveModal(file)}>
                    {i18n.t('buttons:remove')}
                  </Button>
                </FileActionCol>
              </Row>
            </Col>
          ))}
        </Row>
      </StyledAttachmentContainer>
    </div>
  );
}
