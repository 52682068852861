import { ConfigProvider, Grid } from 'antd';
import { Locale } from 'antd/es/locale';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from './components/Spinner';
import './plugins/i18n';
import { setIsMobile } from './plugins/redux/common';
import { routes } from './routes';
import { getLocaleConfig } from './utils/multiLanguage/antdLocale';
import { RootState, ScreenProps } from './utils/types/Types';

const { useBreakpoint } = Grid;

export function App() {
  const { apiCallCount, language } = useSelector(
    (state: RootState) => state.common
  );
  const screens: ScreenProps = useBreakpoint();
  const dispatch = useDispatch();
  const location = useLocation();
  const [locale, setLocale] = useState<Locale>(() => getLocaleConfig(language));

  // Determine if the app is in a mobile view based on screen size
  useEffect(() => {
    const calculateIsMobile = (screens: ScreenProps) => {
      return (screens.xs || screens.sm) && !screens.md;
    };
    dispatch(setIsMobile(calculateIsMobile(screens)));
  }, [dispatch, screens]);

  useEffect(() => {
    setLocale(getLocaleConfig(language));
  }, [language]);

  // Scroll to the top when route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the main scrollbar to the top
  }, [location]);

  return (
    <ConfigProvider locale={locale as Locale}>
      <Spinner spinning={apiCallCount !== 0}>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.page title={route.title} />}
              />
            );
          })}
        </Routes>
      </Spinner>
    </ConfigProvider>
  );
}
