import { Modal as AntdModal, FormInstance, ModalProps } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../utils/types/Types';
import { Spinner } from './Spinner';
import { useKeyDownHandler } from './modal/ModalKeyDownHandler';

/**
 * Component extends the antd Modal with an added Spinner component
 * which is active when there is a pending API call (gets this from redux).
 * This is required as App.jsx has a general Spinner component but it cannot
 * overlay antd Modals.
 */

interface ExtendedModalProps extends ModalProps {
  onOk?: () => void;
  form?: FormInstance;
}

export const Modal = (props: ExtendedModalProps) => {
  const { apiCallCount } = useSelector((state: RootState) => state.common);

  // Use the custom hook for handling Enter key
  useKeyDownHandler({
    isModalOpen: props.open as boolean,
    handleSubmit: props.onOk,
    form: props.form,
  });

  return (
    <AntdModal {...props}>
      <Spinner spinning={apiCallCount !== 0}>{props.children}</Spinner>
    </AntdModal>
  );
};
