import { Button } from 'antd';
import { useState } from 'react';
import { Color } from '../../../elements/base/Color';
import i18n from '../../../plugins/i18n';
import { unshareProject } from '../../../utils/backendRequests/project/unshareProject';
import { Modal } from '../../Modal';

type UnshareProjectLinkProps = {
  projectId: string;
  getProjectList: () => Promise<void>;
};

export function UnshareProjectButton({
  projectId,
  getProjectList,
}: UnshareProjectLinkProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleUnshareProject = async () => {
    const response = await unshareProject(projectId);
    if (response) {
      getProjectList();
      setModalVisible(false); // Close the modal after unshare
    }
  };

  return (
    <>
      <Button danger onClick={() => setModalVisible(true)}>
        {i18n.t('buttons:unshare')}
      </Button>
      <Modal
        title={i18n.t('headers:confirm_unshare')}
        open={modalVisible}
        onOk={handleUnshareProject}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_project_unshare')}</p>
      </Modal>
    </>
  );
}
