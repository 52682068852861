import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Space, Switch, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ButtonSmall,
  DownloadTemplateButton,
} from '../../../elements/buttons/StyledButtons';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import {
  CreateBulkUploadRow,
  CreateLibraryRow,
} from '../../../elements/modals/StyledModal';
import { createBulkUpload } from '../../../utils/backendRequests/bulkUpload/createBulkUpload';
import {
  getLibraries,
  LibraryListType,
} from '../../../utils/backendRequests/list/libraries';
import { configureLanguageTemplates } from '../../../utils/parsers/parseDownloadTemplate';
import {
  DownloadTemplateProp,
  RootState,
  Setter,
} from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { CreateFabricLibraryModal } from '../library/CreateFabricLibraryModal';
import { LibraryType } from '../library/LibraryModalTypes';
import { LibraryDropdown } from './LibraryDropdown';

interface ModalProps {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  fetchData: () => void;
}

export function CreateBulkUploadModal({
  modal,
  setModal,
  fetchData,
}: ModalProps) {
  const language = useSelector((state: RootState) => state.common.language);
  const [libraryId, setLibraryId] = useState<string>('');
  const [libraries, setLibraries] = useState<LibraryListType>([]);
  const [populateWeightFields, setPopulateWeightFields] =
    useState<boolean>(false);
  const [populatePriceFields, setPopulatePriceFields] =
    useState<boolean>(false);
  const [populateMOQFields, setPopulateMOQFields] = useState<boolean>(false);
  const [populateMCQFields, setPopulateMCQFields] = useState<boolean>(false);
  const [populateStockFields, setPopulateStockFields] =
    useState<boolean>(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [createLibrarymodal, setCreateLibrarymodal] = useState({
    visible: false,
  });
  const [newlyCreatedLibrary, setNewlyCreatedLibrary] =
    useState<LibraryType | null>(null);
  const [downloadTemplateURLS, setDownloadTemplateURLS] =
    useState<DownloadTemplateProp>({
      knit: '',
      lace: '',
      woven: '',
    });

  // Set URLs for template download using language
  useEffect(() => {
    configureLanguageTemplates(language, setDownloadTemplateURLS);
  }, [language]);

  useEffect(() => {
    if (!modal.visible) {
      setLibraryId(''); // Resetting to an empty string
      setPopulateWeightFields(false);
      setPopulatePriceFields(false);
      setPopulateMOQFields(false);
      setPopulateMCQFields(false);
      setPopulateStockFields(false);
      setFileList([]);
    }
  }, [modal.visible]);

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleFileRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const getLibraryList = async () => {
    const data = await getLibraries();
    setLibraries(data);
  };

  useEffect(() => {
    getLibraryList();
  }, []);

  const handleOk = async () => {
    if (fileList.length > 0 && libraryId) {
      const formData = new FormData();
      const file = fileList[0];
      formData.append('xlsx_file', file.originFileObj as Blob);
      const details = {
        library_id: libraryId,
        populate_weight_fields: populateWeightFields,
        populate_price_fields: populatePriceFields,
        populate_moq_fields: populateMOQFields,
        populate_mcq_fields: populateMCQFields,
        populate_stock_fields: populateStockFields,
      };
      formData.append('details', JSON.stringify(details));

      const response = await createBulkUpload(formData);

      if (response) {
        setModal({ visible: false });
        // Reset the form
        setPopulateWeightFields(false);
        setPopulatePriceFields(false);
        setPopulateMOQFields(false);
        setPopulateMCQFields(false);
        setPopulateStockFields(false);
        setLibraryId(''); // Ensure the dropdown resets
        setFileList([]);
        setNewlyCreatedLibrary(null);
        fetchData();
      } else {
        // Handle the error case
        message.error(i18n.t('error_messages:error_upload_failed'));
      }
    } else {
      // Handle the case where no file is selected or library is not set
      message.error(i18n.t('error_messages:error_select_file_library'));
    }
  };

  const props = {
    maxCount: 1,
    accept: '.xlsx',
    beforeUpload: () => false, // Allow files of any size
    onChange: handleFileChange,
    onRemove: handleFileRemove,
    fileList,
  };

  const handleCreateNewLibrary = () => {
    setCreateLibrarymodal({ visible: true });
  };

  // Set dropdown to newest created library
  useEffect(() => {
    if (newlyCreatedLibrary) {
      setLibraryId(newlyCreatedLibrary.id);
    }
  }, [newlyCreatedLibrary]);

  return (
    <Modal
      title={i18n.t('headers:create_fabrics')}
      open={modal.visible}
      onOk={handleOk}
      cancelButtonProps={{ danger: true }}
      onCancel={() => setModal({ visible: false })}
      width={650}
    >
      <Row style={{ marginBottom: -10 }}>
        <Col span={24}>
          <Space>
            <p>1. {i18n.t('long_messages:message_create_step_1')}</p>
            <DownloadTemplateButton
              rel="noopener noreferrer"
              href={downloadTemplateURLS.knit}
            >
              <ButtonSmall>
                <DownloadOutlined />
                {i18n.t('buttons:knit_template')}
              </ButtonSmall>
            </DownloadTemplateButton>
            <DownloadTemplateButton
              rel="noopener noreferrer"
              href={downloadTemplateURLS.lace}
            >
              <ButtonSmall>
                <DownloadOutlined />
                {i18n.t('buttons:lace_template')}
              </ButtonSmall>
            </DownloadTemplateButton>
            <DownloadTemplateButton
              rel="noopener noreferrer"
              href={downloadTemplateURLS.woven}
            >
              <ButtonSmall>
                <DownloadOutlined />
                {i18n.t('buttons:woven_template')}
              </ButtonSmall>
            </DownloadTemplateButton>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: -10 }}>
        <Col span={24}>
          <p>2. {i18n.t('long_messages:message_create_step_2')}</p>
        </Col>
      </Row>
      <Row style={{ marginBottom: -10 }}>
        <Col span={24}>
          <p>3. {i18n.t('long_messages:message_create_step_3')}</p>
        </Col>
      </Row>
      <Row style={{ marginBottom: -10 }}>
        <Col span={24}>
          <p>4. {i18n.t('long_messages:message_create_step_4')}</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        {i18n.t('headers:library')}:
        <LibraryDropdown
          value={libraryId}
          setLibraryId={setLibraryId}
          libraries={libraries}
        />
      </Row>
      <CreateLibraryRow>
        <Button onClick={handleCreateNewLibrary}>
          {i18n.t('headers:create_library')}
        </Button>
      </CreateLibraryRow>
      {createLibrarymodal && (
        <CreateFabricLibraryModal
          setModal={setCreateLibrarymodal}
          modal={createLibrarymodal}
          setNewlyCreatedLibrary={setNewlyCreatedLibrary as Setter<LibraryType>}
          getLibraryList={getLibraryList}
        />
      )}
      <CreateBulkUploadRow>{i18n.t('headers:input_file')}:</CreateBulkUploadRow>
      <Row wrap={false}>
        <Upload {...props}>
          <Button disabled={fileList.length !== 0} icon={<UploadOutlined />}>
            {i18n.t('buttons:select_files')}
          </Button>
        </Upload>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <CreateBulkUploadRow>
            <Space>
              {i18n.t('headers:populate_weight_fields')}
              <HelpIcon
                title={i18n.t(
                  'long_messages:message_populate_weight_fields_explain'
                )}
                pos="right"
                fontSize="10px"
                margin="0 -3px 0 -3px"
              />
              :
              <Switch
                checked={populateWeightFields}
                onChange={setPopulateWeightFields}
              />
            </Space>
          </CreateBulkUploadRow>
        </Col>
        <Col span={12}>
          <CreateBulkUploadRow>
            <Space>
              {i18n.t('headers:populate_price_fields')}
              <HelpIcon
                title={i18n.t(
                  'long_messages:message_populate_price_fields_explain'
                )}
                pos="right"
                fontSize="10px"
                margin="0 -3px 0 -3px"
              />
              :
              <Switch
                checked={populatePriceFields}
                onChange={setPopulatePriceFields}
              />
            </Space>
          </CreateBulkUploadRow>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <CreateBulkUploadRow>
            <Space>
              {i18n.t('headers:populate_moq_fields')}
              <HelpIcon
                title={i18n.t(
                  'long_messages:message_populate_moq_fields_explain'
                )}
                pos="right"
                fontSize="10px"
                margin="0 -3px 0 -3px"
              />
              :
              <Switch
                checked={populateMOQFields}
                onChange={setPopulateMOQFields}
              />
            </Space>
          </CreateBulkUploadRow>
        </Col>
        <Col span={12}>
          <CreateBulkUploadRow>
            <Space>
              {i18n.t('headers:populate_mcq_fields')}
              <HelpIcon
                title={i18n.t(
                  'long_messages:message_populate_mcq_fields_explain'
                )}
                pos="right"
                fontSize="10px"
                margin="0 -3px 0 -3px"
              />
              :
              <Switch
                checked={populateMCQFields}
                onChange={setPopulateMCQFields}
              />
            </Space>
          </CreateBulkUploadRow>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <CreateBulkUploadRow>
            <Space>
              {i18n.t('headers:populate_stock_fields')}
              <HelpIcon
                title={i18n.t(
                  'long_messages:message_populate_stock_fields_explain'
                )}
                pos="right"
                fontSize="10px"
                margin="0 -3px 0 -3px"
              />
              :
              <Switch
                checked={populateStockFields}
                onChange={setPopulateStockFields}
              />
            </Space>
          </CreateBulkUploadRow>
        </Col>
      </Row>
    </Modal>
  );
}
