import { Checkbox, Col, ColorPicker, Input, Row, Space } from 'antd';
import { AggregationColor } from 'antd/es/color-picker/color';
import i18n from 'i18next';
import { useState } from 'react';
import { createColour } from '../../../utils/backendRequests/colours/createColour';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { CreateColourModalProps } from './ColourModalTypes';

// Define a type for the ColorFactory instance
type ColorFactoryInstance = AggregationColor;

export function CreateColourModal({
  modal,
  setModal,
  onSuccess,
}: CreateColourModalProps) {
  const defaultColor = new AggregationColor('#000000'); // Initialize with default color
  const [name, setName] = useState('');
  const [colorHex, setColorHex] = useState<ColorFactoryInstance>(defaultColor);
  const [formatHex, setFormatHex] = useState<'hex' | 'rgb' | 'hsb' | undefined>(
    'hex'
  );
  const [nullColor, setNullColor] = useState<boolean>(false);

  const handleCancel = () => {
    setModal({ visible: false });
    setName('');
    setColorHex(defaultColor); // Reset color to default
    setNullColor(false);
  };

  const handleNullColor = () => {
    setNullColor(!nullColor);
  };

  const handleOk = async () => {
    if (stringValid(name, 50, i18n.t('common:name'), false)) {
      let createResponse = false;
      if (nullColor) {
        createResponse = await createColour(name, null);
      } else {
        createResponse = await createColour(name, colorHex.toHexString());
      }
      if (createResponse) {
        onSuccess();
        setNullColor(false);
        setName('');
        setColorHex(defaultColor); // Reset color to default after success
        setModal({ visible: false });
      }
    }
  };

  // Function to handle color change in ColorPicker
  const handleColorChange = (value: AggregationColor) => {
    setColorHex(value); // Update colorHex state with the new color
  };

  return (
    <Modal
      title={i18n.t('headers:new_colour')}
      open={modal.visible}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      onOk={handleOk}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {`${i18n.t('common:name')}:`}
          <Input
            name={i18n.t('common:name')}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Col>
        <Col span={24}>
          <Row>{`${i18n.t('column_titles:colour')}:`}</Row>
          <Space>
            <ColorPicker
              value={colorHex}
              format={formatHex}
              onChange={handleColorChange} // Update to use handleColorChange function
              onFormatChange={setFormatHex}
              disabledAlpha={true}
              disabled={nullColor}
              showText
            />
            <Checkbox checked={nullColor} onChange={handleNullColor}>
              {i18n.t('column_titles:no_color')}
            </Checkbox>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
}
