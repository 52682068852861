import { Button } from 'antd';
import { useState } from 'react';
import { Color } from '../../../elements/base/Color';
import i18n from '../../../plugins/i18n';
import { unshareCollection } from '../../../utils/backendRequests/collection/unshareCollection';
import { Modal } from '../../Modal';
import { UnshareCollectionLinkProps } from './CollectionListTypes';

export function UnshareCollectionButton({
  collectionId,
  getCollectionsList,
}: UnshareCollectionLinkProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleUnshareCollection = async () => {
    const response = await unshareCollection(collectionId);
    if (response) {
      getCollectionsList();
      setModalVisible(false); // Close the modal after unshare
    }
  };

  return (
    <>
      <Button danger onClick={() => setModalVisible(true)}>
        {i18n.t('buttons:unshare')}
      </Button>
      <Modal
        title={i18n.t('headers:confirm_unshare')}
        open={modalVisible}
        onOk={handleUnshareCollection}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_collection_unshare')}</p>
      </Modal>
    </>
  );
}
