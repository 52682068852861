import { Button, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import i18n from '../../../plugins/i18n';
import { editDesignerUser } from '../../../utils/backendRequests/admin/editDesignerUser';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { DropdownOption, SetPropsWithDropDownObj } from '../../form/FormTypes';
import { DesignerUserValues } from '../../table/TableTypes';
import { designerUserEditFields } from './userFormFields';
import { userFieldRules } from './userFormRules';

interface UpdateDesignerUserModalProps {
  modal: { visible: boolean };
  setModal: (modal: { visible: boolean }) => void;
  user: DesignerUserValues;
  userId: string;
  getUsers: () => void;
}

type dropDownDataType = {
  account_type: {
    value: number;
    label: string;
  }[];
  country_id: {
    value: number;
    label: string;
  }[];
  measurement_unit: {
    value: number;
    label: string;
  }[];
};

export function UpdateDesignerUserModal({
  modal,
  setModal,
  user,
  userId,
  getUsers,
}: UpdateDesignerUserModalProps) {
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  // Generic function to translate field titles
  const parseFieldTitle = (fieldName: string) => {
    return i18n.t(`${fieldName}`);
  };

  const fabricRulesConst = useMemo(
    () => userFieldRules(designerUserEditFields, parseFieldTitle),
    [designerUserEditFields]
  );

  useEffect(() => {
    if (modal.visible && user && Object.keys(propsWithDropDownObj).length > 0) {
      //TODO: This logic needs to be removed in future where
      //return ID not string from backend
      const findMatchingOption = (
        options: DropdownOption[],
        label: number | string
      ) => options?.find((option) => option.label === label)?.value ?? null;

      // Prepare updated user object without mutating the original
      const updatedUser = {
        ...user,
        measurement_unit: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.measurement_unit,
          user.measurement_unit ?? ''
        ),
        country_id: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.country_id,
          user.country ?? ''
        ),
        account_type: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.account_type,
          user.account_type ?? ''
        ),
      };
      form.setFieldsValue(updatedUser);
    }
  }, [modal.visible, propsWithDropDownObj]);

  // Close modal
  const handleCancel = () => {
    setModal({ visible: false });
  };

  const handleSubmit = async (values: DesignerUserValues) => {
    const parsedUsers = nullParser(values) as DesignerUserValues;
    // Call the updateUser function with the updated user data
    if (await editDesignerUser(parsedUsers, userId)) {
      setModal({ visible: false });
      getUsers();
      form.resetFields();
    }
  };

  return (
    <Modal
      title={`Update Designer User`}
      open={modal.visible}
      onCancel={handleCancel}
      form={form}
      footer={null}
    >
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={designerUserEditFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={
              setPropsWithDropDownObj as SetPropsWithDropDownObj
            }
            parseFieldTitle={parseFieldTitle}
            formRules={fabricRulesConst}
          />
          <Form.Item wrapperCol={{ offset: 20, span: 12 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:update')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
