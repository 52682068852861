import i18n from '../../plugins/i18n';

export type FooterLink = {
  title: string;
  path: string;
};

export const delvifyFooterURLs: FooterLink[] = [
  {
    title: 'Delvify',
    path: 'https://delvify.ai/',
  },
  {
    title: i18n.t('buttons:terms_of_service'),
    path: 'https://delvify.ai/legal-mmp/',
  },
  {
    title: i18n.t('buttons:privacy_policy'),
    path: 'https://delvify.ai/privacy-policy/',
  },
  {
    title: i18n.t('buttons:contact_us'),
    path: 'mailto:hello@delvify.ai',
  },
];
