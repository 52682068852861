import { useState } from 'react';

import { LinkStyledButton } from '../../../elements/buttons/StyledButtons';
import { UpdateSupplierUserModal } from '../../modal/createUser/UpdateSupplierUserModal';
import { SupplierUserValues } from '../TableTypes';

interface EditSupplierUser {
  user: SupplierUserValues;
  supplierUserId: string;
  getUsers: () => void;
}

export function EditSupplierUser({
  user,
  supplierUserId,
  getUsers,
}: EditSupplierUser) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <LinkStyledButton type="link" onClick={handleUpdate}>
          Edit
        </LinkStyledButton>
      </div>
      <UpdateSupplierUserModal
        modal={updateModal}
        setModal={setUpdateModal}
        user={user}
        userId={supplierUserId}
        getUsers={getUsers}
      />
    </>
  );
}
