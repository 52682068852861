import { message } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { InputRedOnError } from '../../../elements/InputRedOnError';
import {
  CreateInspirationCol,
  ImageUploadWrapper,
} from '../../../elements/modals/StyledModal';
import { createInspiration } from '../../../utils/backendRequests/inspirations/createInspiration';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { ImageUpload } from '../../upload/ImageUpload';
import { CreateInspirationModalProps } from '../ModalTypes';

export function CreateInspirationModal({
  modal,
  onSuccess,
  setModal,
}: CreateInspirationModalProps) {
  const [description, setDescription] = useState('');
  const [errorDescription, setErrorDescription] = useState(false);
  const [img, setImg] = useState<File | null>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  // Allow onOk key press only if the child modal is closed, case with two open modals
  // wait for child to close, before allowing on Enter key press for parent modal
  const [isChildModalClosed, setIsChildModalClosed] = useState<boolean>(true);

  // Sets states to default values to reset modal
  const resetModal = () => {
    setDescription('');
    setErrorDescription(false);
    setImg(null);
    setImgUrl(null);
    setModal({
      visible: false,
    });
  };

  // Close modal
  const handleCancel = () => {
    resetModal();
  };

  const handleOk = () => {
    setErrorDescription(false);

    // No image found
    if (img === null) {
      message.error(i18n.t('error_messages:error_null_image'));

      return null;
    }

    if (
      stringValid(description, 100, i18n.t('column_titles:description'), true)
    ) {
      const descriptionValue = description === '' ? null : description;
      const data = new FormData();
      const detailsJSON = {
        description: descriptionValue,
      };
      data.append('image_upload', img);
      data.append('details', JSON.stringify(detailsJSON));

      createInspiration(data, resetModal, onSuccess);
    } else {
      setErrorDescription(true);
    }
  };

  return (
    <Modal
      title={i18n.t('headers:new_inspiration_upload')}
      open={modal.visible}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      okText={i18n.t('buttons:upload')}
      {...(isChildModalClosed && { onOk: handleOk })} // Conditionally include onOk
    >
      <CreateInspirationCol>
        <ImageUploadWrapper>
          <ImageUpload
            fieldName={i18n.t('column_titles:image')}
            imageUrl={imgUrl}
            setImage={setImg}
            setImageUrl={setImgUrl}
            setIsChildModalClosed={setIsChildModalClosed}
          />
        </ImageUploadWrapper>
        <InputRedOnError
          $err={errorDescription}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDescription(e.target.value)
          }
          showCount={true}
          style={{ marginTop: '20px' }}
          value={description}
        />
      </CreateInspirationCol>
    </Modal>
  );
}
