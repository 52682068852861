import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import { Color } from '../base/Color';

const { Sider } = Layout;

export const StyledSider = styled(Sider)<{ $isMobileFlag: boolean }>`
  ${(props) =>
    !props.$isMobileFlag &&
    `
    position: sticky !important;
    top: 0px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10;
  `}
`;

interface MenuWrapperProps {
  $black?: boolean;
  $isMobile?: boolean;
}

export const MenuWrapper = styled(Menu)<MenuWrapperProps>`
  color: ${(props) => (props.$black === true ? Color.Black : Color.G4)};
  font-size: 14px;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  text-transform: uppercase;

  /* Conditional styles for desktop (when $isMobile is false) */
  ${(props) =>
    !props.$isMobile &&
    `
      position: sticky;
      top: 0px;
      height: 100%;
      overflow-y: auto;
      z-index: 10;
    `}

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(
      0,
      0,
      0,
      0.15
    ); /* Semi-transparent black for visibility */
    border-radius: 6px; /* Slight rounding for a modern look */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Keep the track invisible or default */
  }
`;
