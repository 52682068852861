import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../../plugins/i18n';
import { Color } from '../base/Color';

interface ButtonBasicProps {
  $small?: boolean;
}

export const ButtonBasic = styled(Button)<ButtonBasicProps>`
  height: ${(props) => (props.$small ? '35px' : '48px')};
  font-size: 15px;
  line-height: 24px;
`;

export const SearchButton = styled(ButtonBasic)`
  margin-left: 15px;
  color: ${Color.White};
  background: ${Color.P5};
`;

export const SubmitButton = styled(ButtonBasic)`
  width: 100%;
  background-color: ${Color.Logo};
  color: ${Color.White};
`;

export const DownloadTemplateButton = styled.a`
  padding-left: 5px;
`;

export const FabricStyledButton = styled(Button)`
  width: 100%;
  height: 48px;
`;

export const MobileSearchButton = styled(Button)`
  max-width: 200px;
  margin-left: 10px;
  margin-bottom: 5px;
  font-size: 18px;
`;

export const MobileHeaderSearchButton = styled(Button)`
  margin-right: 10px;
  font-size: 18px;
`;

export const DesktopSidebarButton = styled(Button)`
  font-size: 18px;
`;

export const UserInformationButton = styled(ButtonBasic)`
  float: right;
`;

type FabricSubmitProps = {
  submitTextKey: string;
};

// Create a functional component that accepts title as a prop
export const SaveFabricButton = ({ submitTextKey }: FabricSubmitProps) => (
  <FabricStyledButton type="primary" htmlType="submit">
    {i18n.t(`buttons:${submitTextKey}`)}
  </FabricStyledButton>
);

export const DropdownButton = styled(ButtonBasic)`
  text-decoration: underlined;
  border: none !important;
  box-shadow: none !important;
  padding: 0 8px;
  height: auto;
  display: block;
  width: 100%;
  text-align: center;
  background: none !important;
`;

// Fabric Filter Drawer Buttons
export const ResetFilterButton = styled(Button)`
  width: 100%;
`;

export const ApplyFilterButton = styled(Button)`
  width: 100%;
  margin-top: 5px;
`;

// Send Collection Modal Button
export const SendCollectionButton = styled(Button)`
  margin-top: 10px;
`;

export const ShareCollectionButton = styled(Button)`
  margin-top: 10px;
`;

export const OrangeLogoButton = styled(ButtonBasic)`
  background: ${Color.Logo};
  color: white !important;
  border: none; /* Ensuring no border initially */
  padding: 10px 20px; /* Set consistent padding */
  transition: background 0.3s ease; /* Smooth transition for hover effect */

  &:hover {
    background: #e66450 !important; /* Slightly darker shade */
    color: white !important;
    border: none; /* Keep border consistent */
  }
`;

export const SelectFabricsButton = styled(ButtonBasic)`
  height: 32px;
`;

export const LinkStyledButton = styled(Button)`
  padding: 0;
`;

export const ButtonSmall = styled(Button)<ButtonBasicProps>`
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  height: auto;
  width: auto;
`;

// Sidebar Button Links
interface StyledLinkProps {
  $isMobileFlag: boolean;
  $currentPath: string;
  $routePath: string;
}

const isActiveRoute = ($currentPath: string, $routePath: string): boolean => {
  if ($routePath === '/') {
    return $currentPath === '/'; // Home route exact match
  }
  return $currentPath.startsWith($routePath);
};

export const SidebarLink = styled(Link)<StyledLinkProps>`
  font-size: ${({ $isMobileFlag }) => ($isMobileFlag ? '18px' : '14px')};
  color: ${({ $currentPath, $routePath }) =>
    isActiveRoute($currentPath, $routePath)
      ? Color.Logo
      : Color.Black} !important;
  background-color: ${({ $currentPath, $routePath }) =>
    isActiveRoute($currentPath, $routePath) ? '#F5F5F5' : 'inherit'};
  border-radius: 20px;
  padding: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin: 0;
  box-sizing: border-box;
`;
