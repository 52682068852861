import { Upload } from 'antd';
import { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageCropperModal } from 'react-image-editor-pro';
import { useSelector } from 'react-redux';
import { ImageUploadStyle } from '../../elements/cropper/StyledCropper';
import { UploadWrapper } from '../../elements/wrapper/UploadWrapper';
import i18n from '../../plugins/i18n';
import { parseScaledImageFile } from '../../utils/parsers/parseScaledImageFile';
import { RootState, Setter } from '../../utils/types/Types';
import { AddImageTitle } from './AddImageTitle';
import { CustomUploadRequestOption } from './FileUpload';

interface ImageUploadProps {
  fieldName: string;
  setImage: Setter<File | null>;
  imageUrl: string | null;
  setImageUrl: Setter<string | null>;
  setIsChildModalClosed?: Setter<boolean>;
}

export function ImageUpload({
  fieldName,
  setImage,
  imageUrl,
  setImageUrl,
  setIsChildModalClosed,
}: ImageUploadProps) {
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [originalImageFile, setOriginalImageFile] = useState<File | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [scaledImageUrl, setScaledImageUrl] = useState<string | null>(null);

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  //Display intial existing images
  useEffect(() => {
    if (imageUrl !== null) {
      setCroppedImageUrl(imageUrl);
    }
  }, [imageUrl]);

  // Handle setAllowKeySearch when modalVisible changes
  useEffect(() => {
    if (setIsChildModalClosed) {
      setIsChildModalClosed(!modalVisible);
    }
  }, [modalVisible, setIsChildModalClosed]);

  const props = {
    maxCount: 1,
    showUploadList: false,
    customRequest(options: unknown) {
      const { file } = options as CustomUploadRequestOption;
      setOriginalImageFile(file); // Store the original image file
      setImage(file);
      getBase64(file, (url: string | null) => {
        if (url !== null) {
          setImageUrl(url);
          setScaledImageUrl(null); // Reset the scaled image URL
          setModalVisible(true);
          parseScaledImageFile(url).then((file) => {
            const newUrl = URL.createObjectURL(file);
            setScaledImageUrl(newUrl);
          });
        }
      });
    },
  };

  const getBase64 = (img: File, callback: (url: string | null) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      callback(reader.result as string | null)
    );
    reader.readAsDataURL(img);
  };

  return (
    <div style={{ width: isMobileFlag ? 'auto' : '100%' }}>
      <Upload {...props}>
        <UploadWrapper>
          {croppedImageUrl ? (
            <ImageUploadStyle src={croppedImageUrl} alt={fieldName} />
          ) : (
            <AddImageTitle />
          )}
        </UploadWrapper>
      </Upload>
      <ImageCropperModal
        title={i18n.t('headers:edit_image')}
        reset={i18n.t('buttons:reset')}
        cancel={i18n.t('buttons:cancel')}
        upload={i18n.t('buttons:upload')}
        yPosition={i18n.t('headers:y_position')}
        xPosition={i18n.t('headers:x_position')}
        properties={i18n.t('headers:properties')}
        rotate={i18n.t('headers:rotate')}
        zooming={i18n.t('headers:zoom')}
        isMobile={isMobileFlag}
        setImage={setImage}
        imageUrl={imageUrl}
        setCroppedImageUrl={setCroppedImageUrl}
        setScaledImageUrl={setScaledImageUrl}
        originalImageFile={originalImageFile}
        scaledImageUrl={scaledImageUrl}
        setImageUrl={setImageUrl}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
      />
    </div>
  );
}
