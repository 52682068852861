import { Button, Form, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import { FormModalFooter } from '../../../elements/modals/StyledModal';
import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import {
  UpdateUserProps,
  editUser,
} from '../../../utils/backendRequests/user/editUser';
import { UserProps } from '../../../utils/backendRequests/user/getCurrentUser';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { DropdownOption, SetPropsWithDropDownObj } from '../../form/FormTypes';
import { userFieldRules } from '../createUser/userFormRules';
import { updateUserFields } from './UserFormFields';

interface EditUserModalProps {
  modal: { visible: boolean };
  setModal: (modal: { visible: boolean }) => void;
  user: UserProps;
  onSuccess: () => void;
}

type dropDownDataType = {
  account_type: {
    value: number;
    label: string;
  }[];
  measurement_unit: {
    value: number;
    label: string;
  }[];
};

export function EditUserModal({
  modal,
  setModal,
  user,
  onSuccess,
}: EditUserModalProps) {
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  // Generic function to translate field titles
  const parseFieldTitle = (fieldName: string) => {
    return i18n.t(`${fieldName}`);
  };

  const userRulesConst = useMemo(
    () => userFieldRules(updateUserFields, parseFieldTitle),
    [updateUserFields]
  );

  useEffect(() => {
    if (user && Object.keys(propsWithDropDownObj).length > 0) {
      //TODO: This logic needs to be removed in future where
      //return ID not string from backend
      const findMatchingOption = (
        options: DropdownOption[],
        label: number | string
      ) =>
        options?.find(
          (option) => i18n.t(`account_types:${option.label}`) === label
        )?.value ?? null;
      // Prepare updated user object without mutating the original
      const updatedUser = {
        ...user,
        measurement_unit: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.measurement_unit,
          user.measurement_unit ?? ''
        ),
        account_type: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.account_type,
          user.account_type ?? ''
        ),
      };
      form.setFieldsValue(updatedUser);
    }
  }, [modal.visible, propsWithDropDownObj]);

  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  const handleSubmit = async (values: UpdateUserProps) => {
    const editUserResponse = await editUser(values, user.id);
    if (editUserResponse) {
      onSuccess();
      setModal({
        visible: false,
      });
    }
  };

  return (
    <Modal
      title={i18n.t('page_titles:edit_user')}
      open={modal.visible}
      onCancel={handleCancel}
      form={form}
      footer={null}
    >
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={updateUserFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={
              setPropsWithDropDownObj as SetPropsWithDropDownObj
            }
            formRules={userRulesConst}
            parseFieldTitle={parseFieldTitle}
          />
          <Form.Item wrapperCol={{ offset: 20, span: 12 }}>
            <FormModalFooter>
              <Space>
                <Button danger onClick={handleCancel}>
                  {i18n.t('buttons:cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {i18n.t('buttons:update')}
                </Button>
              </Space>
            </FormModalFooter>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
